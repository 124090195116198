module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","name":"Tata za matu","short_name":"Tata za matu","start_url":"https://tatazamatu.rs/","background_color":"#f7f0eb","theme_color":"#a2466c","display":"standalone","icons":[{"src":"src/favicons/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"src/favicons/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2320e1b28c379701cadb6e331ced8153"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"C:\\Users\\Dejan\\Desktop\\TATA ZA MATU SAJT\\tatazamatu"},
    }]
